var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4 w-full dF fC f1 pb-4 hide-scrollbar",staticStyle:{"overflow-y":"scroll"}},[_c('div',{staticClass:"dF aC"},[_c('a-icon',{attrs:{"type":"arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}})],1),_c('div',[_c('a-tabs',{attrs:{"size":"large","default-active-key":"1"}},[_c('div',{staticClass:"dF aC",staticStyle:{"gap":"25px"},attrs:{"slot":"tabBarExtraContent"},slot:"tabBarExtraContent"},[_c('div',{staticClass:"dF aC",staticStyle:{"gap":"5px"}},[_c('span',[_vm._v("Print:")]),_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.downloadPDF.apply(null, arguments)}}},[_vm._v(" Charts ")]),_c('PDFOrientation',{staticClass:"ml-2"})],1)]),_c('a-tab-pane',{key:"1",attrs:{"tab":"Overview"}},[_c('a-card',{staticClass:"table-card mb-4"},[_c('a-table',{staticClass:"tableStyle",attrs:{"columns":_vm.columns,"data-source":_vm.campaigns,"row-key":(record) => record.id},scopedSlots:_vm._u([{key:"actions",fn:function(record){return _c('div',{staticClass:"text-center"},[(_vm.selectedCampaign == record.name)?_c('a-button',{attrs:{"type":"primary"}},[_vm._v("SELECTED")]):_c('a-button',{on:{"click":function($event){_vm.selectedCampaign = record.name; _vm.getCampaignData()}}},[_vm._v("SELECT")])],1)}}])})],1),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":true,"float-layout":false,"enable-download":false,"preview-modal":false,"pdf-content-width":"100%","manual-pagination":true,"html-to-pdf-options":{
						margin: _vm.pdfOrientation === 'portrait' ? [50.2, 10, 50.2, 10] : [10, 50, 5, 50],
						image: { type: 'jpeg', quality: 2 },
						enableLinks: true,
						html2canvas: { scale: 1, useCORS: true },
						jsPDF: {
							orientation: _vm.pdfOrientation
						}
					}},on:{"beforeDownload":function($event){return _vm.beforeDownload($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[(!_vm.error)?_c('div',{staticClass:"report-grid"},_vm._l((_vm.metricsData),function(item,index){return _c('div',{key:index,staticClass:"html2pdf__page-break"},[_c('a-card',{attrs:{"bordered":false,"loading":_vm.loading}},[_c('div',{staticClass:"dF aC jE",attrs:{"data-html2canvas-ignore":"true"}},[_c('span',{staticClass:"mr-2"},[_vm._v("Chart Type:")]),_c('a-select',{staticClass:"text-primary",staticStyle:{"width":"80px"},attrs:{"showArrow":true},model:{value:(_vm.metricDataChartType[item.label]),callback:function ($$v) {_vm.$set(_vm.metricDataChartType, item.label, $$v)},expression:"metricDataChartType[item.label]"}},[_c('a-icon',{staticClass:"text-primary",attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{staticClass:"text-primary",attrs:{"value":"bar"}},[_vm._v(" Bar ")]),_c('a-select-option',{staticClass:"text-primary",attrs:{"value":"line"}},[_vm._v(" Line ")]),_c('a-select-option',{staticClass:"text-primary",attrs:{"value":"pie"}},[_vm._v(" Pie ")])],1)],1),_c('eChartLine',{key:'title',attrs:{"option":_vm.chartOptions[item.label]}})],1)],1)}),0):_c('div',{staticClass:"mt-10",staticStyle:{"text-align":"center"}},[_c('h4',[_vm._v("Something went wrong. Please Try again!")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.getCampaignData}},[_vm._v("Retry")])],1)])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }